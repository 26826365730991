import { useState } from 'react';
import { Container, Box, Heading, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';

import { PageTestimonial } from '@/modules/home/homeEntity';
import { ImageKey, images } from '@/shared/assets';
import { AspectRatio, Flex, VStack } from '@chakra-ui/react';
import { CustomCarousel } from '@/uikit/component/Carousel';

type TestimonialItem = {
  name: string;
  position: string;
  shortVersion: string;
  longVersion: string;
  image: string;
  program: string;
};

function Testimonials({ data }: { data: PageTestimonial }) {
  return (
    <Box
      position={'relative'}
      overflow={'hidden'}
      w={'full'}
      bgGradient={
        'linear(90deg, rgba(98, 247, 239, 0.08) -0.04%, rgba(94, 91, 250, 0.12) 74.99%)'
      }>
      <Box
        width="1028px"
        height="400px"
        position="absolute"
        top="-300px"
        left="50%"
        transform="translateX(-50%)"
        borderRadius="50%"
        opacity="0.8"
        background="#0FC5FF66"
        filter="blur(171.12298583984375px)"
        zIndex={-1}
      />
      <Container
        id="portfolio"
        maxW="7xl"
        px={{ base: 4, md: 20 }}
        py={{ base: 8, md: 16 }}
        overflow="clip">
        <Heading
          as="h2"
          fontSize={{ base: 'xl', md: '3xl' }}
          textAlign="center"
          mb={12}>
          {data.title}
        </Heading>
        <CustomCarousel>
          {data.testimonials.map((portfolio, i) => (
            <Box key={i} paddingRight='16px'>
              <TestimonialsCard data={portfolio} />
            </Box>
          ))}
        </CustomCarousel>
      </Container>
    </Box>
  );
}

function TestimonialsCard({ data }: { data: TestimonialItem }) {
  return (
    <VStack
      width="276px"
      height="full"
      rounded="12px"
      padding="16px"
      gap="12px"
      bgColor="white"
      position="relative"
      overflow="hidden">
      <Box
        position="absolute"
        width="400px"
        height="305px"
        top="15%"
        transform="translateX(-50%)"
        left="50%"
        zIndex={-1}
        borderRadius="50%"
        opacity="0.8px"
        background="#0FC5FF66"
        filter="blur(171.12px)"
      />
      <Flex gap="8px" alignItems="center" width="full">
        <Box
          position="relative"
          display="inline-block"
          borderRadius="12px"
          p="2px" // Spasi untuk border
          bgGradient="linear(to-r, blue.400, green.200)"
          rounded="full">
          <AspectRatio boxSize="48px" ratio={1}>
            <Image
              alt={data.name}
              src={images(data.image as ImageKey)}
              objectFit="contain"
              width="48px"
              height="48px"
              rounded="full"
            />
          </AspectRatio>
        </Box>
        <Box>
          <Heading as='h3' fontSize="md" lineHeight="24px" fontWeight='normal' fontFamily='body' color="#2C313A">
            {data.name}
          </Heading>
          <Text fontSize="xs" fontWeight="bold" color="#2C313A">
            {data.program}
          </Text>
        </Box>
      </Flex>

      <VStack gap="8px">
        <Text fontSize="sm" color="#2C313A">
          {data.longVersion}
        </Text>
      </VStack>
    </VStack>
  );
}

export { Testimonials };
